import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { Col, Row } from "react-bootstrap";

export const GoogleButton = ({
  onSuccess,
  onFail,
  buttonText = "signin_withs",
}) => {
  return (
    <div className={"d-flex justify-content-center bg-dark py-2 rounded-3"}>
      <GoogleLogin
        onSuccess={onSuccess}
        onError={onFail}
        useOneTap={false}
        theme={"filled_black"}
        text={buttonText}
        size={"large"}
      />
    </div>
  );
};
