import React from "react";
import { Image } from "react-bootstrap";
import whiteColorOutlineLogo from "../../assets/logos/white-color.png";
import { AppConstants } from "../../utils/appConstants";

function Brand({
  nameColor = "text-white",
  nameSize = "fs-4",
  imageWidth = 30,
  marginStart = "ms-0",
}) {
  return (
    <div className="d-flex align-items-center">
      <Image
        src={whiteColorOutlineLogo}
        alt={AppConstants.appName}
        width={imageWidth}
        height={imageWidth}
      />
      <p className={`${marginStart} mb-0 fw-bolder ${nameColor} ${nameSize}`}>
        {AppConstants.appName}
      </p>
    </div>
  );
}

export default Brand;
