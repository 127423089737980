import React, { useContext } from "react";
import { AppContext } from "./components/AppContext";
import { ToastContext } from "./components/ToastContext";
import { OffCanvasContext } from "./components/OffCanvasContext";

export const useToast = () => {
  const { addToast } = useContext(ToastContext);

  const showToast = ({ title, message, success = false }) => {
    addToast({
      id:
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15),
      title,
      message,
      success,
      show: true,
    });
  };

  return { showToast };
};

export const useOffCanvas = () => {
  const { openOffCanvas } = useContext(OffCanvasContext);

  const showOffCanvas = ({ title, subtitle, component }) => {
    openOffCanvas({ title, subtitle, component });
  };
  return { showOffCanvas };
};
export const updateObjectState = (state, updateValues) => {
  state((prevState) => ({
    ...prevState,
    ...updateValues,
  }));
};

export const isEmpty = (item) => {
  const emptyItems = [``, " ", null, undefined];
  return emptyItems.includes(item);
};

export const formatMoney = (val) => {
  if (typeof val !== "string") {
    // val = Math.ceil(val * 100) / 100
    val = val.toFixed(2).toString();
  }
  let amt = val.replace(".", "").replace(/,/g, "");
  const last = amt.length - 1;
  const secondLast = amt.length > 1 ? amt.length - 2 : null;
  let beforeDecimal = amt.length > 2 ? amt.substr(0, secondLast) : "0";
  beforeDecimal = parseInt(beforeDecimal).toLocaleString("en-US");
  return `${beforeDecimal}.${
    secondLast ? amt.charAt(secondLast) : "0"
  }${amt.charAt(last)}`;
};

export const globalReplaceComma = (val, replacer = "") => {
  return val.replace(/,/g, replacer);
};

export const strToFloat = (val) => {
  val = globalReplaceComma(val);
  return parseFloat(val);
};

export function extractItemsFromObject(objectsArray, key, filterFunction) {
  if (filterFunction) {
    return objectsArray.filter(filterFunction).map((obj) => obj[key]);
  }
  return objectsArray.map((obj) => obj[key]);
}

export function updateStateAtIndex(state, setState, updatedItem, index) {
  const items = [...state];
  items[index] = updatedItem;
  setState(items);
}

export function getObjectFromListByKey(list, key, value) {
  return list.find((item) => item[key] === value) || null;
}

export function getCurrentTimeOfDay() {
  const currentHour = new Date().getHours();

  if (currentHour >= 5 && currentHour < 12) {
    return "morning";
  } else if (currentHour >= 12 && currentHour < 17) {
    return "afternoon";
  } else {
    return "evening";
  }
}
