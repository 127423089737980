import React, { useContext, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Alert,
  Dropdown,
} from "react-bootstrap";
import { FaCopy } from "react-icons/fa";
import { apiRequest } from "../../utils/apiRequests";
import { isEmpty, updateObjectState } from "../../utils/functions";
import { ConditionalView, LoadingView } from "../../utils/components";
import { AppContext } from "../../utils/components/AppContext";
import { logEvent } from "../../utils/Analytics";
import { AppConstants } from "../../utils/appConstants";

function NotesForm({ formData, setFormData, showToast }) {
  const noteFormats = [
    { label: "SOAP", value: "SOAP" },
    { label: "DAP", value: "DAP" },
    { label: "BIRP", value: "BIRP" },
  ];

  const noteTypes = [
    "Brokerage Services",
    "Care Coordination",
    "Case Management",
    "Collateral",
    "Collateral / Caregiver",
    "Collateral / Healthcare Provider",
    "Consultation",
    "Crisis",
    "Discharge Summaries",
    "Face-to-Face Behaviorial Evaluation",
    "Group Therapy",
    "Individual Rehabilitation",
    "Intake",
    "Non Face-to-Face Behaviorial Evaluation",
    "Plan Development",
    "Progress",
    "Psychotherapy",
    "Psychiatric Diagnostic Evaluation",
    "Treatment Plans",
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const { contextState, updateContextState } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const handleGenerateNotes = async (vals) => {
    setIsLoading(true);
    const { success, response } = await apiRequest.post(
      `/user/generate`,
      formData
    );
    if (success) {
      setFormData({ ...formData, formattedNotes: response.formatted_notes });
      updateContextState({
        available_credits: response.available_credits,
      });
      logEvent("User", "Generate Notes", "Notes generated by user");
    } else {
      showToast({
        title: "Error Generating Note",
        message: response.message,
      });
    }
    setIsLoading(false);
  };

  const handleCopyNotes = () => {
    navigator.clipboard.writeText(
      formData.formattedNotes.replace(/<br\/>/g, "\n")
    );
    setShowAlert(true);
    // Hide the "Copied!" alert after 2 seconds
    setTimeout(() => setShowAlert(false), 2000);
  };

  return (
    <Container>
      <Row>
        <Col md={6} className={"pt-4"}>
          <Form>
            <Form.Group controlId="notesTextarea">
              <Form.Label className={"mb-4 fw-bold"}>
                There's no need to change how you take notes - we can work with
                any format you prefer. Enter your notes below in bullet points,
                lists, or any other style that works for you.
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={12}
                name="notes"
                value={formData.notes}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formatSelect" className={"mt-5"}>
              <Form.Label>Select Output Note Format:</Form.Label>
              <div>
                {noteFormats.map((format) => (
                  <Button
                    variant={
                      formData.format === format.value
                        ? "primary"
                        : "outline-primary"
                    }
                    key={format.value}
                    name="format"
                    value={format.value}
                    onClick={(e) => {
                      localStorage.setItem(
                        AppConstants.noteFormatKey,
                        format.value
                      );
                      handleInputChange(e);
                    }}
                    className={"me-3 rounded-pill"}
                  >
                    {format.label}
                  </Button>
                ))}
              </div>
            </Form.Group>
            <Form.Group controlId="typeSelect" className={"mt-4"}>
              <Form.Label>Select Output Note Type</Form.Label>
              <Dropdown>
                <Dropdown.Toggle variant={"secondary"} className={"w-100"}>
                  {formData.type ? formData.type : "Select Type"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {noteTypes.map((type) => (
                    <Dropdown.Item
                      key={type}
                      onClick={() => {
                        localStorage.setItem(AppConstants.noteTypeKey, type);
                        updateObjectState(setFormData, {
                          type,
                        });
                      }}
                    >
                      {type}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
            <Button
              variant="primary"
              onClick={handleGenerateNotes}
              className={"mt-5 w-100"}
              disabled={isLoading || isEmpty(formData.notes)}
            >
              Generate Note
            </Button>
          </Form>
        </Col>
        <Col md={6} className={"pt-4"}>
          <h2>Formatted Note</h2>
          <ConditionalView
            condition={isLoading}
            trueView={
              <div
                className={
                  "text-center h-100 d-flex align-items-center justify-content-center flex-column"
                }
              >
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <h5 className={"mt-4"}>Generating Notes</h5>
              </div>
            }
            falseView={
              <div>
                <p
                  dangerouslySetInnerHTML={{ __html: formData.formattedNotes }}
                ></p>
                {formData.formattedNotes && (
                  <>
                    <Button
                      onClick={handleCopyNotes}
                      variant={"secondary mt-3"}
                    >
                      <FaCopy size={20} className={"me-2"} />
                      Copy Note
                    </Button>
                    {showAlert && (
                      <Alert
                        variant="success"
                        onClose={() => setShowAlert(false)}
                        dismissible
                        className={"mt-3"}
                      >
                        Copied!
                      </Alert>
                    )}
                  </>
                )}
              </div>
            }
          />
        </Col>
      </Row>
    </Container>
  );
}

export default NotesForm;
