import React from "react";
import { Card, Row, Col } from "react-bootstrap";

function ProfileInfoCard({ profile }) {
  return (
    <Card className="mb-4">
      <Card.Header className={"fw-bold fs-3"}>Profile Information</Card.Header>
      <Card.Body>
        <Row>
          <Col xs={12} md={6}>
            <p>
              <strong>Name:</strong> {profile.first_name} {profile.last_name}
            </p>
          </Col>
          <Col xs={12} md={6}>
            <p>
              <strong>Email:</strong> {profile.email}
            </p>
          </Col>
          <Col xs={12} md={6}>
            <p>
              <strong>Member Since:</strong> {profile.joined}
            </p>
          </Col>
          <Col xs={12} md={6}>
            <p>
              <strong>Available Credits:</strong> {profile.available_credits}
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default ProfileInfoCard;
