import ReactGA from "react-ga4";
import { appConfigs } from "../configs";

export const initGA = () => {
  ReactGA.initialize("G-GH129FWH1X");
  // ReactGA.initialize([
  //     {
  //         trackingId: "G-GH129FWH1X",
  //         gaOptions: {...}, // optional
  //         gtagOptions: {...}, // optional
  //     },
  // ]
};

export const logPageView = () => {
  if (!appConfigs.isProd) return;
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "",
  });
};

export const logEvent = (category, action, label, value = 1) => {
  if (!appConfigs.isProd) return;
  ReactGA.event({
    category: category,
    action: action,
    label: label,
    value: value,
  });
};
