import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import {
  AiOutlineUser,
  AiOutlineSync,
  AiOutlineFileDone,
  AiOutlineSafety,
} from "react-icons/ai";
import { BiTask, BiError } from "react-icons/bi";
import { IoSpeedometerOutline } from "react-icons/io5";

const Features = () => {
  const features = [
    {
      title: "Easy-to-Use Interface",
      description:
        "Notesmate offers a user-friendly interface, allowing you to quickly and easily navigate and use the platform.",
      icon: <AiOutlineUser />,
    },
    {
      title: "AI-Powered Rewriting",
      description:
        "Our advanced AI technology accurately converts your free-form notes into the selected Medi-Cal billing format.",
      icon: <AiOutlineSync />,
    },
    {
      title: "Support for Various Formats",
      description:
        "Notesmate supports a wide range of Medi-Cal billing formats, ensuring you find the perfect option for your practice.",
      icon: <AiOutlineFileDone />,
    },
    {
      title: "Secure Data Handling",
      description:
        "We prioritize your data privacy and security, ensuring that your information is protected throughout the process.",
      icon: <AiOutlineSafety />,
    },
  ];

  const benefits = [
    {
      title: "Increased Productivity",
      description:
        "Save time and increase productivity by automating the conversion of your notes into Medi-Cal billing formats.",
      icon: <IoSpeedometerOutline />,
    },
    {
      title: "Reduced Errors",
      description:
        "Our AI-powered rewriting helps minimize human error and ensures the accuracy of your billing data.",
      icon: <BiError />,
    },
    {
      title: "Improved Billing Efficiency",
      description:
        "Streamline your billing process and improve efficiency, resulting in faster reimbursements and fewer denied claims.",
      icon: <BiTask />,
    },
  ];

  return (
    <div className="py-5 bg-secondary bg-opacity-10" id={"features"}>
      <Container className="">
        <h2 className="text-center mb-5">Key Features of Notesmate</h2>
        <Row className="mb-4">
          {features.map((feature, index) => (
            <Col md={3} key={index} className="text-center mb-4">
              <Card className="shadow h-100">
                <Card.Body>
                  {feature.icon && (
                    <div className="mb-3">
                      {React.cloneElement(feature.icon, { size: 48 })}
                    </div>
                  )}
                  <Card.Title>{feature.title}</Card.Title>
                  <Card.Text>{feature.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <h2 className="text-center mb-5">
          Benefits for Mental Health Clinicians
        </h2>
        <Row>
          {benefits.map((benefit, index) => (
            <Col md={4} key={index} className="text-center mb-4">
              <Card className="shadow h-100">
                <Card.Body>
                  {benefit.icon && (
                    <div className="mb-3">
                      {React.cloneElement(benefit.icon, { size: 48 })}
                    </div>
                  )}
                  <Card.Title>{benefit.title}</Card.Title>
                  <Card.Text>{benefit.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Features;
