export const AppRoutes = {
  // pages
  Index: { path: "/" },
  Home: { path: "/home" },
  SignUp: { path: "/signup" },
  SignIn: { path: "/signin" },
  Verification: { path: "/verify-email" },
  Account: { path: "/account" },
  Terms: { path: "/terms" },
  Privacy: { path: "/privacy" },
};
