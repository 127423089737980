import React, { createContext, useState } from "react";

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [contextState, setContextState] = useState({
    authenticated: false,
    available_credits: 0,
    user: {},
  });

  const updateContextState = (updatedState) => {
    setContextState({ ...contextState, ...updatedState });
  };

  return (
    <AppContext.Provider
      value={{ contextState, updateContextState, setContextState }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
