import React from "react";
import { Offcanvas } from "react-bootstrap";
import { useOffCanvas } from "./OffCanvasContext";

function AppOffCanvas() {
  const { show, content, closeOffCanvas } = useOffCanvas();

  return (
    <Offcanvas show={show} onHide={closeOffCanvas} placement={"end"}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{content.title}</Offcanvas.Title>
      </Offcanvas.Header>
      <div className={"mb-4 px-3"}>{content.subtitle}</div>
      <Offcanvas.Body>{content.component}</Offcanvas.Body>
    </Offcanvas>
  );
}

export default AppOffCanvas;
