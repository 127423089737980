import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Row, Col, Card, Tabs, Tab } from "react-bootstrap";
import { LoadingView } from "../utils/components";
import DashboardPageContainer from "./components/DashboardPageContainer";
import { apiRequest } from "../utils/apiRequests";
import { useNavigate } from "react-router-dom";
import { getCurrentTimeOfDay, useToast } from "../utils/functions";
import { AppContext } from "../utils/components/AppContext";
import { AppRoutes } from "../utils/appRoutes";
import TopNavbar from "./components/TopNavbar";
import NoteForm from "./Forms/NoteForm";
import PricingPage from "./components/PricingTable";
import { AppConstants } from "../utils/appConstants";
import ProfileInfo from "./components/ProfileInfo";
import { FaCheckCircle } from "react-icons/fa";
import DeleteAccountPrompt from "./components/DeleteAccountPrompt";
import { logEvent } from "../utils/Analytics";

function Account() {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { contextState, updateContextState } = useContext(AppContext);

  useEffect(() => {
    getAccountInfo();
  }, []);

  const [activeTab, setActiveTab] = useState("profile");
  const [configs, setConfigs] = useState({});
  const [profile, setProfile] = useState({});
  const [info, setInfo] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getAccountInfo = async () => {
    setIsLoading(true);
    const { success, response } = await apiRequest.get(`/user/account`);
    if (success) {
      setInfo(response.info);
      setConfigs(response.configs);
      setProfile(response.profile);
    } else {
      showToast({
        title: "Error Getting Info",
        message: response.message,
      });
    }
    setIsLoading(false);
  };

  const getPortalLink = async () => {
    setIsLoading(true);
    const { success, response } = await apiRequest.get(`/user/billing`);
    if (success) {
      // window.open(response.portal_url);
      window.location.href = response.portal_url;
    } else {
      showToast({
        title: "Error Getting Info",
        message: response.message,
      });
    }
    setIsLoading(success);
  };

  const subscribe = async (price_id) => {
    setIsLoading(true);
    const { success, response } = await apiRequest.get(
      `/user/subscribe/${price_id}`
    );
    if (success) {
      // window.open(response.portal_url);
      window.location.href = response.url;
    } else {
      showToast({
        title: "Error Getting Info",
        message: response.message,
      });
    }
    setIsLoading(success);
  };

  const deleteAccount = async (price_id) => {
    setIsLoading(true);
    const { success, response } = await apiRequest.get(`/user/delete`);
    if (success) {
      logEvent("User", "Delete", `Account Deletion`);
      updateContextState({
        user: {},
        authenticated: false,
        confirmed: false,
      });
      localStorage.setItem(AppConstants.authTokenKey, null);
      navigate(AppRoutes.Index.path);
    } else {
      showToast({
        title: "Error Getting Info",
        message: response.message,
      });
    }
    setIsLoading(success);
  };

  return (
    <DashboardPageContainer
      info={info}
      pageComponent={
        <LoadingView
          authRequired={true}
          isLoading={isLoading}
          view={
            <div>
              <ProfileInfo profile={profile} />
              {configs.subscribed ? (
                <>
                  <div className="d-flex flex-column align-items-center justify-content-center text-center py-3">
                    {configs.active_subscription ? (
                      <div>
                        <h4 className="mb-4 text-center">
                          You are currently subscribed to {AppConstants.appName}
                          !
                        </h4>
                        <h5>{configs.active_subscription?.name} Plan</h5>
                        <p>{configs.active_subscription?.description}</p>
                        <p>Billed Monthly</p>
                        <Button
                          variant="primary"
                          onClick={() => {
                            getPortalLink();
                          }}
                        >
                          {configs.active_subscription
                            ? "Manage Your Subscription"
                            : "View Available Subscriptions"}
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <h4 className="mb-5 text-center">
                          eactivate your subscription now to unlock the full
                          potential of our AI-powered note generation platform.
                        </h4>
                        <Row className={"justify-content-center"}>
                          {configs.subscription_plans.map((plan, i) => {
                            return (
                              <Col key={i} md={3} className="mb-4">
                                <Card className="text-center h-100 border-1 shadow-lg border-secondary rounded-3">
                                  <Card.Header className={"py-5"}>
                                    <h3>{plan.name}</h3>
                                  </Card.Header>
                                  <Card.Body as={"div"} className={"py-4"}>
                                    <Card.Title>
                                      <h1>
                                        {plan.price}
                                        <small className={"fs-5"}>
                                          {" "}
                                          / month
                                        </small>
                                      </h1>
                                    </Card.Title>
                                    <Card.Text>{plan.description}</Card.Text>
                                  </Card.Body>
                                  <Card.Footer as={"div"} className={"py-5"}>
                                    <Button
                                      variant="primary"
                                      className={"w-100"}
                                      onClick={() => {
                                        subscribe(plan.price_id);
                                      }}
                                    >
                                      Subscribe Now
                                    </Button>
                                  </Card.Footer>
                                </Card>
                              </Col>
                            );
                          })}
                        </Row>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <h3 className={"my-5 text-center"}>
                    Start your {AppConstants.appName} subscription today
                  </h3>
                  <PricingPage
                    stripe_key={configs.stripe_key}
                    table_id={configs.subscription_table_id}
                    customer_email={configs.customer_email}
                    client_reference={configs.client_reference}
                  />
                </>
              )}
              <div className={"d-flex justify-content-end mt-4"}>
                <Button
                  variant={"outline-danger"}
                  onClick={() => setShowDelete(true)}
                >
                  Delete My Account
                </Button>

                <DeleteAccountPrompt
                  show={showDelete}
                  onHide={() => setShowDelete(false)}
                  handleDelete={deleteAccount}
                />
              </div>
            </div>
          }
        />
      }
    />
  );
}

export default Account;
