import { Container, Row, Col, Card } from "react-bootstrap";
import TopNavbar from "./components/TopNavbar";

function TermsOfService() {
  return (
    <div className="bg-secondary bg-opacity-10 min-vh-100">
      <TopNavbar />
      <Container className="mt-5">
        <Row className="justify-content-center pt-3">
          <Col md={8}>
            <Card className="py-5">
              <Card.Body>
                <h2 className="text-center mb-4">Terms of Service</h2>
                <ol>
                  <li>
                    <h3>Confidentiality</h3>
                    <p>
                      We take the confidentiality and privacy of your
                      information seriously. We do not log or save any of the
                      notes you enter into our system. To protect the privacy of
                      your clients, we strongly advise against entering any
                      personally identifiable information (PII), such as names
                      or addresses.
                    </p>
                  </li>
                  <li>
                    <h3>Use of AI Technology</h3>
                    <p>
                      Our AI technology assists with generating formatted notes,
                      but the final decision on content and accuracy is your
                      responsibility. We are not accountable for any errors or
                      omissions in the notes generated by our system.
                    </p>
                  </li>
                  <li>
                    <h3>User Accounts</h3>
                    <p>
                      You may only create and use one account on our platform.
                      Your account and any associated credits are
                      non-transferable.
                    </p>
                  </li>
                  <li>
                    <h3>Prohibited Activities</h3>
                    <p>
                      You agree not to use our platform for any illegal or
                      unauthorized purposes or to violate any laws in your
                      jurisdiction. Our platform may not be used to harass,
                      abuse, or harm others or to transmit offensive or
                      inappropriate content. We reserve the right to suspend or
                      terminate your account if we suspect any violations of
                      these Terms of Service.
                    </p>
                  </li>
                  <li>
                    <h3>Payment and Refunds</h3>
                    <p>
                      Our credit-based system requires payment for credits to
                      generate notes. We offer a free trial and monthly
                      subscription plans for purchasing credits. Refunds are not
                      provided for unused credits.
                    </p>
                  </li>
                  <li>
                    <h3>Changes to Terms of Service</h3>
                    <p>
                      We reserve the right to modify these Terms of Service at
                      any time. Any changes will be posted on our website and
                      will become effective immediately upon posting.
                    </p>
                  </li>
                </ol>
                <p>
                  By using our platform, you agree to these Terms of Service. If
                  you have any questions or concerns, please contact us.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TermsOfService;
