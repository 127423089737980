const webAddress = "notesmate.co";
const env = process.env.REACT_APP_ENV;

const sharedConfigs = {
  isDev: false,
  isStg: false,
  isProd: false,
  apiHostname: "http://10.0.0.104:5000",
  feHostname: "http://10.0.0.104:3000",
  googleClientID:
    "553880392163-ui88l6sssv70qu0ncpglbbbjq5oltln3.apps.googleusercontent.com",
  googleRedirectUrl: "http://locahlost:3000",
  useGoogle: true,
};

const configs = {
  dev: {
    ...sharedConfigs,
    ...{
      isDev: true,
    },
  },
  stg: {
    ...sharedConfigs,
    ...{
      isStg: true,
      apiHostname: `https://stg-api.${webAddress}`,
      feHostname: `https://stg.${webAddress}`,
      googleRedirectUrl: `https://stg.${webAddress}/signup`,
    },
  },
  prod: {
    ...sharedConfigs,
    ...{
      isProd: true,
      apiHostname: `https://api.${webAddress}`,
      feHostname: `https://${webAddress}`,
      googleRedirectUrl: `https://${webAddress}/signup`,
    },
  },
};

export const appConfigs = configs[env];
