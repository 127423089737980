import React, { useState, useEffect, useContext } from "react";
import { Navbar, Nav, Image, Dropdown, Button } from "react-bootstrap";
import Brand from "./Brand";
import { AppRoutes } from "../../utils/appRoutes";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../utils/functions";
import { AppContext } from "../../utils/components/AppContext";
import { apiRequest } from "../../utils/apiRequests";
import { AppConstants } from "../../utils/appConstants";
import { FaUser } from "react-icons/fa";

function TopNavbar({ showAll = false }) {
  const navigate = useNavigate();

  const { showToast } = useToast();
  const { contextState, updateContextState } = useContext(AppContext);

  const handleSignOut = async () => {
    const { success, response } = await apiRequest.get(`/user/signout`);
    if (success) {
      updateContextState({
        authenticated: false,
        user: { name: null },
      });
      localStorage.setItem(AppConstants.authTokenKey, null);
      navigate(AppRoutes.Index.path);
    } else {
      showToast({
        title: "Error Getting Info",
        message: response.message,
      });
    }
  };

  const goToPage = (url) => {
    navigate(url);
  };

  return (
    <Navbar expand={"lg"} className={`fixed-top border-bottom bg-white`}>
      <Navbar.Brand
        href={"#"}
        onClick={() => {
          navigate(AppRoutes.Home.path);
        }}
        className={"ms-2"}
      >
        <Brand
          marginStart={"ms-1"}
          nameColor={"text-dark"}
          imageWidth={40}
          nameSize={"fs-4"}
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbar-nav" />
      {showAll && (
        <>
          <Navbar.Collapse id="navbar-nav-1">
            <Nav className="me-auto">
              {/*<Nav.Link href="#">Home</Nav.Link>*/}
              <Nav.Link href="#hiw">How It Works</Nav.Link>
              <Nav.Link href="#features">Features & Benefits</Nav.Link>
              <Nav.Link href="#pricing">Pricing & Plans</Nav.Link>
              {/*<Nav.Link href="/about">About Us</Nav.Link>*/}
              <Nav.Link href="#contact">Contact Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </>
      )}
      <Navbar.Collapse id="navbar-nav">
        <Nav className={"ms-auto"}>
          {!contextState.authenticated && (
            <>
              <Nav.Item className={"ms-auto me-2"}>
                <Nav.Link
                  onClick={() => {
                    goToPage(AppRoutes.SignIn.path);
                  }}
                >
                  Sign In
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className={"ms-auto me-2"}>
                <Button
                  variant={"primary"}
                  onClick={() => {
                    goToPage(AppRoutes.SignUp.path);
                  }}
                >
                  Sign Up
                </Button>
              </Nav.Item>
            </>
          )}
          {contextState.user.name && (
            <Nav.Item className={"ms-auto me-2"}>
              <Dropdown>
                <Dropdown.Toggle
                  variant="transparent"
                  className={"bg-transparent text-dark"}
                >
                  <span>{contextState.user.name}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="ms-auto">
                  <Dropdown.Item
                    onClick={() => {
                      goToPage(AppRoutes.Account.path);
                    }}
                  >
                    Account
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={handleSignOut}>
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Item>
          )}
          {contextState.authenticated && !contextState.user.name && (
            <Nav.Item className={"ms-auto me-2"}>
              <Button
                variant={"primary"}
                onClick={() => {
                  goToPage(AppRoutes.Home.path);
                }}
              >
                <FaUser className={"me-1 mb-1"} />
                My Account
              </Button>
            </Nav.Item>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
export default TopNavbar;
