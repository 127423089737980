import React, { useContext, useEffect, useState } from "react";
import { LoadingView } from "../utils/components";
import DashboardPageContainer from "./components/DashboardPageContainer";
import { apiRequest } from "../utils/apiRequests";
import { useNavigate } from "react-router-dom";
import { useToast } from "../utils/functions";
import { AppContext } from "../utils/components/AppContext";
import { AppRoutes } from "../utils/appRoutes";
import TopNavbar from "./components/TopNavbar";
import NoteForm from "./Forms/NoteForm";
import { Container } from "react-bootstrap";
import Headline from "./IndexPageComponents/Header";
import HowItWorks from "./IndexPageComponents/HowItWorks";
import Features from "./IndexPageComponents/Features";
import Pricing from "./IndexPageComponents/Pricing";
import Footer from "./IndexPageComponents/Footer";
import CallToAction from "./IndexPageComponents/CallToAction";

function Index() {
  const navigate = useNavigate();

  return (
    <div className={"min-vh-100 min-vw-100"}>
      <TopNavbar showAll={true} />
      <Headline />
      <HowItWorks />
      <Features />
      <Pricing />
      <CallToAction
        title="Ready to simplify your Medi-Cal billing process?"
        description="Get started with Notesmate today and experience the benefits of our AI-powered tool."
        buttonText="Sign Up for Free"
        buttonLink="/signup"
      />
      <Footer />
    </div>
  );
}

export default Index;
