import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

function DeleteAccountPrompt({ show, onHide, handleDelete }) {
  const [confirmInput, setConfirmInput] = useState("");

  const handleChange = (e) => {
    setConfirmInput(e.target.value);
  };

  const handleConfirmDelete = () => {
    handleDelete();
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to delete your account? Your active subscription
          wil be cancelled if you have one. This action cannot be undone. To
          confirm, please type "confirm" in the input below.
        </p>
        <input
          type="text"
          className="form-control"
          value={confirmInput}
          onChange={handleChange}
          placeholder={"confirm"}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={handleConfirmDelete}
          disabled={confirmInput !== "confirm"}
        >
          Delete Account
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteAccountPrompt;
