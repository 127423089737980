import { Card, Col, Container, Row } from "react-bootstrap";
import TopNavbar from "./components/TopNavbar";

function PrivacyPolicy() {
  return (
    <div className="bg-secondary bg-opacity-10 min-vh-100">
      <TopNavbar />
      <Container className="mt-5">
        <Row className="justify-content-center pt-3">
          <Col md={8}>
            <Card className="py-5">
              <Card.Body>
                <h2 className="text-center mb-4">Privacy Policy</h2>
                <ol>
                  <li>
                    <h3>Collection of Personal Information</h3>
                    <p>
                      We collect personal information when you register for our
                      services, which includes your name, email address, and
                      payment information. However, we do not gather any
                      personally identifiable information (PII) from your
                      clients, such as names or addresses.
                    </p>
                  </li>
                  <li>
                    <h3>Use of Personal Information</h3>
                    <p>
                      We use your personal information to offer and enhance our
                      services, to communicate with you, and to process
                      payments. Your personal information will not be sold,
                      rented, or shared with any third parties.
                    </p>
                  </li>
                  <li>
                    <h3>Storage of Personal Information</h3>
                    <p>
                      Your personal information is stored on secure servers, and
                      we implement reasonable measures to protect your
                      information from unauthorized access or disclosure.
                    </p>
                  </li>
                  <li>
                    <h3>Cookies</h3>
                    <p>
                      Cookies are utilized to improve your experience on our
                      platform and to analyze usage trends. While you may
                      disable cookies in your browser settings, doing so may
                      impact the functionality of our platform.
                    </p>
                  </li>
                  <li>
                    <h3>Third-Party Services</h3>
                    <p>
                      We may employ third-party services, such as payment
                      processors, to facilitate payments and enhance our
                      services. These services may have their own privacy
                      policies and terms of service that you should review.
                    </p>
                  </li>
                  <li>
                    <h3>Data Retention</h3>
                    <p>
                      We retain your personal information for as long as
                      necessary to provide our services and to comply with legal
                      obligations. However, we do not retain any of the notes
                      you enter or those generated from the ones you enter. We
                      strongly encourage you not to include any personally
                      identifiable information (PII) of your clients within the
                      notes to ensure their privacy and security.
                    </p>
                  </li>
                  <li>
                    <h3>Changes to Privacy Policy</h3>
                    <p>
                      We reserve the right to modify this Privacy Policy at any
                      time. Any changes will be posted on our website and will
                      become effective immediately upon posting.
                    </p>
                  </li>
                </ol>
                <p>
                  If you have any questions or concerns about our Privacy
                  Policy, please contact us at{" "}
                  <a href="mailto:support@notesmate.co">support@notesmate.co</a>
                  .
                </p>
                <p>
                  By using our platform, you consent to this Privacy Policy. If
                  you do not agree with this Privacy Policy, please do not use
                  our platform.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PrivacyPolicy;
