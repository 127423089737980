import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

const CallToAction = ({ title, description, buttonText, buttonLink }) => {
  return (
    <div className="bg-primary text-white py-5">
      <Container className={"my-5"}>
        <Row>
          <Col md={8} className="d-flex flex-column justify-content-center">
            <h2 className="mb-3">{title}</h2>
            <p className="lead">{description}</p>
          </Col>
          <Col
            md={4}
            className="d-flex align-items-center justify-content-md-end mt-3 mt-md-0"
          >
            <Button
              href={buttonLink}
              variant="light"
              className={"btn-lg text-primary"}
            >
              {buttonText}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CallToAction;
