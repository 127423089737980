import { useContext, useEffect, useState } from "react";
import { Card, Form, Button, Container, Col, Row } from "react-bootstrap";
import { BsEnvelope, BsLock } from "react-icons/bs";
import logo from "../assets/logos/white-color.png";
import { apiRequest } from "../utils/apiRequests";
import { LoadingView } from "../utils/components";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../utils/appRoutes";
import { isEmpty, useToast } from "../utils/functions";
import { AppConstants } from "../utils/appConstants";
import { AppContext } from "../utils/components/AppContext";
import { GoogleButton } from "./components/GoogleButton";
import { appConfigs } from "../configs";

function SignIn() {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { contextState, updateContextState } = useContext(AppContext);

  useEffect(() => {
    if (contextState.authenticated) {
      navigate(AppRoutes.Home.path);
    }
  }, [contextState.authenticated]);

  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSignIn = async (vals, endpoint) => {
    const formData = vals || { ...data };
    setIsLoading(true);
    const { success, response } = await apiRequest.post(endpoint, formData);
    if (success) {
      localStorage.setItem(AppConstants.authTokenKey, response.token);
      updateContextState({
        authenticated: true,
      });
      navigate(AppRoutes.Home.path);
      return;
    } else {
      showToast({
        message: response.message,
      });
    }
    setIsLoading(false);
  };

  const formValid = () => {
    const requiredFields = ["email", "password"];
    let valid = true;
    for (const field of requiredFields) {
      if (isEmpty(data[field])) {
        valid = false;
        break;
      }
    }
    return valid;
  };
  const googleSuccess = (response) => {
    const { credential } = response;
    handleSignIn({ id_token: credential }, "/user/google/signin");
  };

  const googleFail = (response) => {
    showToast({
      title: "Unable to complete Sign In",
      message: response,
    });
  };

  return (
    <LoadingView
      isLoading={isLoading}
      addAppToasts={true}
      view={
        <Container fluid={true}>
          <Row className="justify-content-center align-items-center min-vh-100">
            <Col md={8} lg={6} xl={4}>
              <Card
                className={
                  "w-f00 border-primary border-1 rounded-start shadow-lg"
                }
              >
                <Card.Header className="text-center py-4">
                  <img
                    src={logo}
                    className={"pointer"}
                    alt="Company Logo"
                    style={{ width: 80, height: "auto" }}
                    onClick={() => {
                      navigate(AppRoutes.Index.path);
                    }}
                  />
                  <h3 className={"my-3"}>
                    Sign In To Your {AppConstants.appName} Account
                  </h3>
                </Card.Header>
                <Card.Body>
                  {appConfigs.useGoogle && (
                    <div>
                      <GoogleButton
                        onSuccess={googleSuccess}
                        onFail={googleFail}
                      />
                    </div>
                  )}
                  <Form
                    className={""}
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSignIn(null, "/user/signin");
                    }}
                  >
                    <h5 className={"text-center my-5"}>or use email</h5>
                    <Form.Group controlId="formBasicEmail" className={"mb-4"}>
                      <Form.Label>Email address</Form.Label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <BsEnvelope />
                        </span>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Enter email"
                          value={data.email}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group
                      controlId="formBasicPassword"
                      className={"mb-5"}
                    >
                      <Form.Label>Password</Form.Label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <BsLock />
                        </span>
                        <Form.Control
                          type="password"
                          name="password"
                          placeholder="Password"
                          value={data.password}
                          onChange={handleInputChange}
                        />
                      </div>
                    </Form.Group>

                    <Button
                      variant="primary"
                      type="submit"
                      className="w-100"
                      disabled={!formValid()}
                    >
                      Sign In
                    </Button>
                  </Form>
                </Card.Body>
                <Card.Footer>
                  <div className="py-4 d-flex justify-content-between align-items-center">
                    <a href="/reset-password">
                      <strong>Forgot Your Password?</strong>
                    </a>
                    <a href="/signup">
                      <strong>Need an Account?</strong>
                    </a>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      }
    />
  );
}

export default SignIn;
