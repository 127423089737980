import { useContext, useEffect, useState } from "react";
import { Card, Form, Button, Container, Row, Col } from "react-bootstrap";
import { BsEnvelope, BsLock, BsPerson } from "react-icons/bs";
import { RiLockPasswordLine } from "react-icons/ri";
import logo from "../assets/logos/white-color.png";
import { apiRequest } from "../utils/apiRequests";
import { LoadingView } from "../utils/components";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../utils/appRoutes";
import { useToast } from "../utils/functions";
import { AppContext } from "../utils/components/AppContext";
import { AppConstants } from "../utils/appConstants";
import { logEvent } from "../utils/Analytics";
import { GoogleButton } from "./components/GoogleButton";
import { FaGoogle } from "react-icons/fa";
import { appConfigs } from "../configs";

const PasswordHint = ["Password must be at least 8 characters long"];

function SignUp() {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { contextState, updateContextState } = useContext(AppContext);

  useEffect(() => {
    if (contextState.authenticated) {
      navigate(AppRoutes.Home.path);
    }
  }, [contextState.authenticated]);

  const [data, setData] = useState({
    email: "",
    password: "",
    repeatPassword: "",
    firstName: "",
    lastName: "",
  });
  const [useGoogle, setUseGoogle] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSignUp = async (vals, endpoint, signUpType = "Email") => {
    setIsLoading(true);
    const formData = vals || { ...data };
    const { success, response } = await apiRequest.put(endpoint, formData);
    if (success) {
      localStorage.setItem(AppConstants.authTokenKey, response.token);
      updateContextState({
        authenticated: true,
        confirmed: vals ? true : false,
      });
      logEvent("User", "Sign Up", `${signUpType} Sign Up`);
      navigate(vals ? AppRoutes.Home.path : AppRoutes.Verification.path);
    } else {
      showToast({
        message: response.message,
      });
    }
    setIsLoading(false);
  };

  const isFormValid = () => {
    return (
      validatePassword(data.password) && data.password === data.repeatPassword
    );
  };

  const validatePassword = (password) => {
    // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    // return passwordRegex.test(password);
    return password.length >= 8;
  };

  const googleSuccess = (response) => {
    const { credential } = response;
    handleSignUp({ id_token: credential }, "/user/google/signup", "Google");
  };

  const googleFail = (response) => {
    showToast({
      title: "Unable to complete Sign In",
      message: response,
    });
  };

  return (
    <LoadingView
      isLoading={isLoading}
      addAppToasts={true}
      view={
        <Container fluid={true}>
          <Row className="justify-content-center align-items-center min-vh-100">
            <Col md={8} lg={6} xl={4}>
              <Card
                className={
                  "w-f00 border-primary border-1 rounded-start shadow-lg"
                }
              >
                <Card.Header className="text-center py-4">
                  <img
                    src={logo}
                    className={"pointer"}
                    alt="Company Logo"
                    style={{ width: 100, height: "auto" }}
                    onClick={() => {
                      navigate(AppRoutes.Index.path);
                    }}
                  />
                  <div className={"my-4"}>
                    <Card.Title as={"h4"}>
                      Sign Up for {AppConstants.appName}
                    </Card.Title>
                    <Card.Subtitle as={"p"}>
                      Take the first step towards stress-free note-taking and
                      improved documentation.
                    </Card.Subtitle>
                  </div>
                </Card.Header>
                <Card.Body>
                  {useGoogle ? (
                    <div className={"mt-3"}>
                      <GoogleButton
                        buttonTest={"signup_with"}
                        onSuccess={googleSuccess}
                        onFail={googleFail}
                      />

                      <Card.Subtitle
                        as={"p"}
                        className="text-muted text-center mb-4 mt-3"
                      >
                        By signing up with Google, you agree to our
                        <br />
                        <a
                          href="/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms of Service
                        </a>{" "}
                        and{" "}
                        <a
                          href="/privacy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy Policy
                        </a>
                        .
                      </Card.Subtitle>
                      <div className={"py-4 text-center fs-6"}>
                        <Button
                          variant={"outline-primary"}
                          className={"w-100 py-2"}
                          onClick={() => {
                            setUseGoogle(false);
                          }}
                        >
                          or use email
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSignUp(null, "/user/signup");
                      }}
                    >
                      {appConfigs.useGoogle && (
                        <div className={"mb-4 text-center"}>
                          <Button
                            variant={"dark"}
                            className={"pointer w-100 py-2"}
                            onClick={() => {
                              setUseGoogle(true);
                            }}
                          >
                            <FaGoogle className={"me-2 mb-1"} />
                            Use Google
                          </Button>
                        </div>
                      )}
                      <Form.Group
                        controlId="formBasicFirstName"
                        className="mb-4"
                      >
                        <Form.Label>First Name</Form.Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <BsPerson />
                          </span>
                          <Form.Control
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            value={data.firstName}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </Form.Group>
                      <Form.Group
                        controlId="formBasicLastName"
                        className="mb-4"
                      >
                        <Form.Label>Last Name</Form.Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <BsPerson />
                          </span>
                          <Form.Control
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            value={data.lastName}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </Form.Group>
                      <Form.Group controlId="formBasicEmail" className="mb-4">
                        <Form.Label>Email address</Form.Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <BsEnvelope />
                          </span>
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Enter email"
                            value={data.email}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </Form.Group>
                      <Form.Group
                        controlId="formBasicPassword"
                        className="mb-4"
                      >
                        <Form.Label>Password</Form.Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <BsLock />
                          </span>
                          <Form.Control
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={data.password}
                            onChange={handleInputChange}
                            isInvalid={
                              data.password && !validatePassword(data.password)
                            }
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            <ul>
                              {PasswordHint.map((hint) => (
                                <li key={hint}>{hint}</li>
                              ))}
                            </ul>
                          </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                      <Form.Group
                        controlId="formBasicRepeatPassword"
                        className="mb-5"
                      >
                        <Form.Label>Repeat Password</Form.Label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <RiLockPasswordLine />
                          </span>
                          <Form.Control
                            type="password"
                            name="repeatPassword"
                            placeholder="Repeat Password"
                            value={data.repeatPassword}
                            onChange={handleInputChange}
                            isInvalid={data.repeatPassword !== data.password}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Passwords do not match.
                          </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                      <Card.Subtitle
                        as={"p"}
                        className="text-muted text-center"
                      >
                        By signing up, you agree to our
                        <br />
                        <a
                          href="/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms of Service
                        </a>{" "}
                        and{" "}
                        <a
                          href="/privacy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy Policy
                        </a>
                        .
                      </Card.Subtitle>

                      <Button
                        variant="primary"
                        type="submit"
                        className={"w-100 mt-4 py-2"}
                        disabled={!isFormValid()}
                      >
                        Sign Up
                      </Button>
                    </Form>
                  )}
                </Card.Body>
                <Card.Footer>
                  <div className="py-4 d-flex justify-content-center align-items-center">
                    <a href="/signin">
                      <strong>Have an Account? Sign In</strong>
                    </a>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      }
    />
  );
}

export default SignUp;
