import AppToasts from "./components/AppToasts";
import React, { useContext, useEffect } from "react";
import { AppContext } from "./components/AppContext";
import { apiRequest } from "./apiRequests";
import { FaPlus } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { AppRoutes } from "./appRoutes";
import { useNavigate } from "react-router-dom";
import { useToast } from "./functions";

export function ConditionalView({ condition, trueView, falseView }) {
  if (condition) {
    return <>{trueView}</>;
  } else {
    return <>{falseView}</>;
  }
}

export function LoadingView({
  isLoading,
  view,
  text = "Please Wait...",
  addAppToasts = false,
  authRequired = false,
}) {
  const navigate = useNavigate();
  const { contextState, updateContextState } = useContext(AppContext);

  useEffect(() => {
    if (!authRequired) return;
    if (!contextState.authenticated) {
      navigate(AppRoutes.SignIn.path);
    }
  }, [contextState.authenticated]);

  return (
    <ConditionalView
      condition={isLoading}
      trueView={
        <div
          className={
            "text-center min-vh-100 d-flex align-items-center justify-content-center flex-column"
          }
        >
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <h5 className={"mt-4"}>{text}</h5>
        </div>
      }
      falseView={
        <div className={"min-vh-100"}>
          {view}
          {addAppToasts && <AppToasts />}
        </div>
      }
    />
  );
}

export function AddItemButton({ title, onClick }) {
  return (
    <div className={"bg-white pe-4 pt-2 pb-1 d-flex justify-content-end gap-0"}>
      <Button
        className={"rounded-3 p-2"}
        variant={"success"}
        size={"sm"}
        style={{ position: "relative" }}
        onClick={() => onClick()}
      >
        <FaPlus className={"me-2"} />
        {title}
      </Button>
    </div>
  );
}
