import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaEnvelope,
  FaInstagram,
  FaFacebookF,
  FaTwitter,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-dark text-white py-5">
      <Container className={"my-5"}>
        <Row>
          <Col md={4} className={"py-4"}>
            <h4>Contact Us</h4>
            <p>
              <FaEnvelope className="me-2" size={25} />
              <a href="mailto:support@notesmate.co" className="text-white">
                support@notesmate.co
              </a>
            </p>
          </Col>
          <Col md={4} className={"py-4"}>
            <h4>Follow Us</h4>
            <a
              href="https://www.instagram.com/notesmate_ai"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white me-3"
            >
              <FaInstagram size={24} />
            </a>
            <a
              href="https://www.facebook.com/notesmate_ai"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white me-3"
            >
              <FaFacebookF size={24} />
            </a>
            <a
              href="https://twitter.com/notesmate_ai"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white"
            >
              <FaTwitter size={24} />
            </a>
          </Col>
          <Col md={4} className={"py-4"}>
            <h4>Legal</h4>
            <ul className="list-unstyled">
              <li className={"mb-3"}>
                <a href="/privacy" className="text-white">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="/terms" className="text-white">
                  Terms of Service
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-4">
            <p>
              &copy; {new Date().getFullYear()} Notesmate. All rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
