import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import background from "../../assets/images/background.png";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../utils/appRoutes";

const Headline = () => {
  const navigate = useNavigate();

  return (
    <div
      className="min-vh-100 d-flex align-items-center"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      id={"#"}
    >
      <Container>
        <Row>
          <Col md={6} className="text-white">
            <h1 className="display-4 mb-3">
              Create Professional Notes with Ease
            </h1>
            <p className="lead mb-5 fw-bold">
              Effortlessly convert your free-form notes into Medi-Cal billing
              format using our AI-powered tool.
            </p>
            <Button
              variant="primary"
              size="lg"
              className={"w-100 text-white p-1 fs-2 rounded-3"}
              onClick={() => {
                navigate(AppRoutes.SignUp.path);
              }}
            >
              Get Started
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Headline;
