import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import CallToAction from "./CallToAction";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../utils/appRoutes";

const Pricing = () => {
  const plans = [
    {
      title: "10 Free Credits",
      description: "Try it out",
      price: "Free",
      benefits: [
        "1 credit = 1 note generated",
        "All users get this upon signup",
      ],
      isFree: true,
    },
    {
      title: "50 Credits Plan",
      description: "Get started",
      price: "$2.99",
      benefits: ["1 credit = 1 note generated"],
    },
    {
      title: "100 Credits Plan",
      description: "Best value",
      price: "$4.99",
      benefits: ["1 credit = 1 note generated"],
    },
    {
      title: "150 Credits Plan",
      description: "Premium",
      price: "$6.99",
      benefits: ["1 credit = 1 note generated"],
    },
  ];
  const navigate = useNavigate();

  return (
    <div className="py-5" id={"pricing"}>
      <Container className="my-5">
        <div className={"text-center mb-5"}>
          <h2 className="mb-2">Pricing</h2>
          <h5>All new users will get 10 FREE credits</h5>
        </div>
        <Row>
          {plans.map((plan, index) => (
            <Col md={6} lg={3} key={index} className="mb-4">
              <Card className="text-center h-100 border-1 shadow-lg border-secondary rounded-3">
                <Card.Header className={"py-5"}>
                  <h3>{plan.title}</h3>
                </Card.Header>
                <Card.Body as={"div"} className={"py-4"}>
                  <Card.Title>
                    <h1>
                      {plan.price}
                      {!plan.isFree && (
                        <small className={"fs-5"}> / month</small>
                      )}
                    </h1>
                  </Card.Title>
                  <Card.Text>{plan.description}</Card.Text>
                  <ul className="list-unstyled">
                    {plan.benefits.map((benefit, benefitIndex) => (
                      <li key={benefitIndex}>{benefit}</li>
                    ))}
                  </ul>
                </Card.Body>
                <Card.Footer as={"div"} className={"py-5"}>
                  <Button
                    variant="primary"
                    className={"w-100"}
                    onClick={() => {
                      navigate(AppRoutes.SignUp.path);
                    }}
                  >
                    Start for FREE
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Pricing;
