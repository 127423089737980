import React, { useContext, useEffect, useState } from "react";
// import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import SignUp from "./pages/SignUp";
import { AppRoutes } from "./utils/appRoutes";
import SignIn from "./pages/SignIn";
import { apiRequest } from "./utils/apiRequests";
import { AppContext } from "./utils/components/AppContext";
import EmailVerification from "./pages/EmailConfirmation";
import Index from "./pages/Index";
import Account from "./pages/Account";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { initGA, logPageView } from "./utils/Analytics";

function RoutesWithAnalytics() {
  const location = useLocation();
  const [initAnalytics, setInitAnalytics] = useState(true);

  useEffect(() => {
    if (initAnalytics) {
      initGA();
      setInitAnalytics(false);
    }
    logPageView();
  }, [location]);

  return (
    <Routes>
      <Route exact path={AppRoutes.Index.path} element={<Index />} />
      <Route exact path={AppRoutes.Home.path} element={<Home />} />
      <Route exact path={AppRoutes.SignUp.path} element={<SignUp />} />
      <Route exact path={AppRoutes.SignIn.path} element={<SignIn />} />
      <Route exact path={AppRoutes.Account.path} element={<Account />} />
      <Route exact path={AppRoutes.Terms.path} element={<TermsOfService />} />
      <Route
        exact
        path={AppRoutes.Verification.path}
        element={<EmailVerification />}
      />
      <Route exact path={AppRoutes.Privacy.path} element={<PrivacyPolicy />} />
    </Routes>
  );
}

function App() {
  const { contextState, updateContextState } = useContext(AppContext);

  const checkAuth = async () => {
    if (contextState.authenticated) return;
    const { success, response } = await apiRequest.get(`/user/authn`);
    updateContextState({
      authenticated: success,
      confirmed: response.confirmed || false,
    });
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <Router>
      <RoutesWithAnalytics />
    </Router>
  );
}

export default App;
