import "./App.scss";
import React from "react";
import ReactDOM from "react-dom/client";
// import "./custom.scss";
import "./index.css";
// import "./App.css";
import "./App.scss";
import App from "./App";
import AppContextProvider, { AppContext } from "./utils/components/AppContext";
import { ToastProvider } from "./utils/components/ToastContext";

import reportWebVitals from "./reportWebVitals";
import { OffCanvasProvider } from "./utils/components/OffCanvasContext";
import { appConfigs } from "./configs";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AppContextProvider>
    <OffCanvasProvider>
      <ToastProvider>
        <GoogleOAuthProvider clientId={appConfigs.googleClientID}>
          <App />
        </GoogleOAuthProvider>
      </ToastProvider>
    </OffCanvasProvider>
  </AppContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
