import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Row, Col, Card } from "react-bootstrap";
import { LoadingView } from "../utils/components";
import DashboardPageContainer from "./components/DashboardPageContainer";
import { apiRequest } from "../utils/apiRequests";
import { useNavigate } from "react-router-dom";
import { getCurrentTimeOfDay, useToast } from "../utils/functions";
import { AppContext } from "../utils/components/AppContext";
import { AppRoutes } from "../utils/appRoutes";
import TopNavbar from "./components/TopNavbar";
import NoteForm from "./Forms/NoteForm";
import { AppConstants } from "../utils/appConstants";

function Home() {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { contextState, updateContextState } = useContext(AppContext);
  const [formData, setFormData] = useState({
    notes: "",
    format: localStorage.getItem(AppConstants.noteFormatKey),
    formattedNotes: "",
    type: localStorage.getItem(AppConstants.noteTypeKey),
  });

  useEffect(() => {
    if (!contextState.authenticated) return;
    if (!contextState.confirmed) {
      navigate(AppRoutes.Verification.path);
    }
    getHomeInfo();
  }, []);

  const [info, setInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getHomeInfo = async () => {
    setIsLoading(true);
    const { success, response } = await apiRequest.get(`/user/home`);
    if (success) {
      if (!response.confirmed) {
        navigate(AppRoutes.Verification.path);
      }
      setInfo(response.info);
      updateContextState({
        available_credits: response.info.available_credits,
        user: response.info,
      });
    } else {
      showToast({
        title: "Error Getting Info",
        message: response.message,
      });
    }
    setIsLoading(false);
  };

  return (
    <DashboardPageContainer
      info={info}
      pageComponent={
        <LoadingView
          authRequired={true}
          isLoading={isLoading}
          view={
            <>
              <Row>
                <Col md={10}>
                  <h2 className={""}>
                    Good {getCurrentTimeOfDay()}, {info.first_name}!
                  </h2>
                  <em>
                    Create professional notes quickly and easily using our
                    AI-powered platform, while ensuring the confidentiality and
                    privacy of your information.
                  </em>
                </Col>
                <Col className="d-flex flex-column align-items-end" md={2}>
                  <div className="mb-3">
                    <strong>Available Credits:</strong>{" "}
                    {contextState.available_credits}
                  </div>
                  <a
                    href={"#"}
                    onClick={() => {
                      navigate(AppRoutes.Account.path);
                    }}
                  >
                    Subscription
                  </a>
                </Col>
              </Row>

              <Card className="my-4 p-1 bg-light border-primary">
                <Card.Body>
                  <Card.Text>
                    Note: We understand that the notes you enter may contain
                    sensitive and confidential information. Please note that
                    while our AI technology can assist with generating formatted
                    notes, the final decision on the content and accuracy of the
                    notes is yours. Additionally, we strongly advise against
                    entering any personally identifiable information (PII) of
                    your clients, such as names or addresses, to protect their
                    privacy. We take your privacy and the privacy of your
                    clients seriously, and want you to feel confident that your
                    information is secure.
                  </Card.Text>
                </Card.Body>
              </Card>
              <NoteForm
                showToast={showToast}
                formData={formData}
                setFormData={setFormData}
              />
            </>
          }
        />
      }
    />
  );
}

export default Home;
