import { useContext, useEffect, useState } from "react";
import { Card, Form, Button, Container, Row, Col } from "react-bootstrap";
import { BsEnvelope } from "react-icons/bs";
import logo from "../assets/logos/white-color.png";
import { apiRequest } from "../utils/apiRequests";
import { useToast } from "../utils/functions";
import { AppConstants } from "../utils/appConstants";
import { AppContext } from "../utils/components/AppContext";
import { LoadingView } from "../utils/components";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../utils/appRoutes";

function EmailVerification() {
  const navigate = useNavigate();

  const { showToast } = useToast();
  const { contextState, updateContextState } = useContext(AppContext);

  useEffect(() => {
    if (contextState.confirmed) {
      navigate(AppRoutes.Home.path);
    }
  }, [contextState.confirmed]);

  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    setCode(event.target.value);
  };

  const handleVerification = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { success, response } = await apiRequest.post(
      "/user/verify-email",
      {
        code: code,
      },
      false
    );

    if (success) {
      updateContextState({
        confirmed: true,
      });
      navigate(AppRoutes.Home.path);
    } else {
      showToast({
        message: response.message + " Please try again",
      });
    }
    setIsLoading(false);
  };

  const signOut = async () => {
    setIsLoading(true);
    const { success, response } = await apiRequest.get(`/user/signout`);
    if (success) {
      localStorage.setItem(AppConstants.authTokenKey, null);
      navigate(AppRoutes.Index.path);
    } else {
      showToast({
        title: "Error Getting Info",
        message: response.message,
      });
    }
    setIsLoading(false);
  };
  const resendCode = async () => {
    setIsLoading(true);
    const { success, response } = await apiRequest.get(
      `/user/resend-verificaion-email`
    );
    if (success) {
      showToast({
        success,
        message:
          "We have sent a new code to your email address. It will expire in 24 hrs.",
      });
    } else {
      showToast({
        title: "Error Re-Sending code",
        message: response.message,
      });
    }
    setIsLoading(false);
  };
  return (
    <LoadingView
      isLoading={isLoading}
      addAppToasts={true}
      view={
        <Container fluid={true}>
          <Row className="justify-content-center align-items-center min-vh-100">
            <Col md={8} lg={6} xl={4}>
              <Card
                className={
                  "w-f00 border-primary border-1 rounded-start shadow-lg"
                }
              >
                <Card.Header className="text-center">
                  <img
                    src={logo}
                    alt="Company Logo"
                    style={{ width: 100, height: "auto" }}
                  />

                  <div className={"my-4"}>
                    <Card.Title as={"h4"}>Verify Your Email Address</Card.Title>
                    <Card.Subtitle as={"p"}>
                      Please Check your inbox for a verification code to
                      activate your Notesmate account. If you don't see the
                      email, check your spam folder or contact us for help. The
                      code will expire in 24 hrs.
                    </Card.Subtitle>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Form onSubmit={handleVerification}>
                    <Form.Group controlId="formBasicEmail" className={"mb-4"}>
                      <Form.Label>Verification Code</Form.Label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <BsEnvelope />
                        </span>
                        <Form.Control
                          type="text"
                          name="code"
                          placeholder="Enter verification code"
                          value={code}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </Form.Group>

                    <Button
                      variant="primary"
                      type="submit"
                      className="w-100"
                      disabled={code.length < 6}
                    >
                      Submit
                    </Button>
                  </Form>
                </Card.Body>
                <Card.Footer>
                  <div className="py-4 d-flex justify-content-between align-items-center">
                    <a
                      href={"#"}
                      className={"text-danger"}
                      onClick={(e) => {
                        e.preventDefault();
                        signOut();
                      }}
                    >
                      <strong>Sign Out</strong>
                    </a>
                    <a
                      href={"#"}
                      onClick={(e) => {
                        e.preventDefault();
                        resendCode();
                      }}
                    >
                      <strong>Re-send code</strong>
                    </a>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      }
    />
  );
}

export default EmailVerification;
