import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import step1 from "../../assets/images/step1.png";
import step2 from "../../assets/images/step2.png";
import step3 from "../../assets/images/step3.png";

const HowItWorks = () => {
  const steps = [
    {
      title: "Step 1: Paste Your Notes",
      description:
        "Paste your notes in bullet points or any format you have them in. Copy and paste your free-form notes or rough notes into the designated input field on Notesmate.",
      imgSrc: step1,
    },
    {
      title: "Step 2: Choose Output Format and Note Type",
      description:
        "Select the desired Medi-Cal billing format and note type from the available options. Notesmate supports various billing formats to cater to your needs.",
      imgSrc: step2,
    },
    {
      title: "Step 3: Submit and Experience the Magic",
      description:
        "Click Generate Note and let Notesmate's AI-powered tool rewrite your free-form notes into the selected Medi-Cal billing format, ensuring accuracy and saving time.",
      imgSrc: step3,
    },
  ];

  return (
    <div className="py-5 bg-primary bg-opacity-75" id={"hiw"}>
      <Container>
        <h2 className="text-center mb-5">How Notesmate Works</h2>
        {steps.map((step, index) => (
          <Row
            key={index}
            className={`mb-4 ${index % 2 === 1 ? "flex-md-row-reverse" : ""}`}
          >
            <Col md={6} className="text-center shadow-lg mt-3">
              <img
                src={step.imgSrc}
                alt={step.title}
                className="img-fluid mb-3"
              />
            </Col>
            <Col md={6} className="d-flex flex-column justify-content-center">
              <h3>{step.title}</h3>
              <p>{step.description}</p>
            </Col>
          </Row>
        ))}
      </Container>
    </div>
  );
};

export default HowItWorks;
