import * as React from "react";

function PricingPage({
  table_id,
  stripe_key,
  customer_email,
  client_reference,
}) {
  return (
    <stripe-pricing-table
      pricing-table-id={table_id}
      publishable-key={stripe_key}
      customer-email={customer_email}
      client-reference-id={client_reference}
    ></stripe-pricing-table>
  );
}

export default PricingPage;
