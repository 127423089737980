import axios from "axios";
import { appConfigs } from "../configs";
import { AppConstants } from "./appConstants";
import { AppRoutes } from "./appRoutes";

class Requests {
  host = appConfigs.apiHostname;
  headers = {
    Authorization: `Bearer ${localStorage.getItem(AppConstants.authTokenKey)}`,
  };

  authToken = null;

  axiosInstance = axios.create({
    baseURL: this.host,
    headers: this.headers,
  });

  constructor() {
    this.axiosInstance.interceptors.request.use((config) => {
      if (!this.authToken || !this.headers.Authorization) {
        this.authToken = localStorage.getItem(AppConstants.authTokenKey);
        if (this.authToken) {
          this.authToken = `Bearer ${this.authToken}`;
          this.headers["Authorization"] = this.authToken;
          config.headers = this.headers;
        }
      }
      return config;
    });
  }

  catchError(error) {
    console.log(`Error: ${error}`);
    // alert(`Error: ${error}`);
    return {
      success: false,
      response: {
        message:
          "An unknown error occurred. Please try again or contact us if this error persists.",
      },
      statusCode: error?.response?.status,
    };
  }

  async sendRequest(
    endpoint,
    method,
    data = null,
    multipart = false,
    extraHeaders = {}
  ) {
    try {
      const requestConfig = {
        method: method,
        headers: {
          ...this.headers,
          ...extraHeaders,
          "Content-Type": multipart
            ? "multipart/form-data"
            : "application/json",
        },
        data: data ? (multipart ? data : JSON.stringify(data)) : undefined,
        validateStatus: function (status) {
          return status >= 200 && status < 600; // Always resolve promise for any status code
        },
      };
      const response = await this.axiosInstance.request(
        endpoint,
        requestConfig
      );
      const statusCode = response.status;
      if ([200, 201].includes(statusCode)) {
        return {
          success: true,
          response: response.data,
          statusCode,
        };
      }

      if (statusCode === 401 && endpoint !== "/user/authn") {
        window.location.href = AppRoutes.SignIn.path;
      }

      return {
        success: false,
        response: response.data,
        statusCode,
      };
    } catch (error) {
      if (!appConfigs.isProd) {
        console.log(error);
      }
      return this.catchError(error);
    }
  }

  async post(endpoint, data = null, multipart = false, extraHeaders = {}) {
    return this.sendRequest(endpoint, "POST", data, multipart, extraHeaders);
  }

  async put(endpoint, data = null, multipart = false, extraHeaders = {}) {
    return this.sendRequest(endpoint, "PUT", data, multipart, extraHeaders);
  }

  async patch(endpoint, data = null, multipart = false, extraHeaders = {}) {
    return this.sendRequest(endpoint, "PATCH", data, multipart, extraHeaders);
  }

  async get(endpoint, extraHeaders = {}) {
    return this.sendRequest(endpoint, "GET", undefined, false, extraHeaders);
  }

  async delete(endpoint, extraHeaders = {}) {
    return this.sendRequest(endpoint, "DELETE", undefined, false, extraHeaders);
  }
}

export const apiRequest = new Requests();

export const RequestResult = {
  success: false,
  response: null,
  statusCode: null,
};
