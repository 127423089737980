import React, { useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import TopNavbar from "./TopNavbar";
import AppToasts from "../../utils/components/AppToasts";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../utils/functions";
import { AppContext } from "../../utils/components/AppContext";
import { AppRoutes } from "../../utils/appRoutes";
import AppOffCanvas from "../../utils/components/AppOffCanvas";

function DashboardPageContainer({ info, pageComponent }) {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { contextState, updateContextState } = useContext(AppContext);

  useEffect(() => {
    if (!contextState.authenticated) {
      navigate(AppRoutes.SignIn.path);
    }
  }, [contextState.authenticated]);

  return (
    <>
      <div className={"bg-secondary bg-opacity-10"}>
        <TopNavbar />
        <Container
          className={"bg-white py-5 px-4 rounded-3 border-2 border-primary"}
        >
          <div style={{ paddingTop: 30, paddingBottom: 30 }}>
            {pageComponent}
          </div>
        </Container>
      </div>
      <AppToasts />
      <AppOffCanvas />
    </>
  );
}

export default DashboardPageContainer;
